import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs-compat';
import { IndexAttributes } from '../interfaces/index-attributes.interface';
import { ConditionsIndex } from '../interfaces/conditions-index.interface';
import { SequenceTagDetails } from '../interfaces/sequence-tag-details.interface';
import { tap } from 'rxjs/operators';
import { AlertGrid } from '../interfaces/alert-grid.interface';
import { SpeedMeasurement } from '../interfaces/speed-measurement.interface';
import { PointType } from '../interfaces/point-type.type';
import { KeyValue } from '../interfaces/key-value.interface';
import { environment } from 'src/environments/environment';
import { TestsResults, VersionTests } from '../interfaces/actas-tests.interface';
import { ActasResultsArticleNumber } from 'src/app/featureModules/admin/actas/interfaces/actas-results.interface';
import { ActasParameters } from 'src/app/featureModules/admin/actas/interfaces/actas-parameters.interface';

interface UploadResult {
	name: string;
	type: string;
	size: number;
	url: string;
}

@Injectable({
    providedIn: 'root'
})
export class ActasProductDetailsService {

    private _indexes: number[] = [];
    private alertMessage$: BehaviorSubject<AlertGrid> = new BehaviorSubject<AlertGrid>(undefined);
    
    public baseUrl: string = environment.ACTAS_BASE_URI;


    constructor( private http: HttpClient ) {}

    // --------- Common Endpoints
    
    public setCommonHeaders(factoryId: number, userId: number): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('factoryId', factoryId.toString())
        headers = headers.append('userId', userId.toString());
        return headers;
    }

    public getConditionsDataApiByType(factoryId: number, userId: number, type: string): Observable<ConditionsIndex[]> {
        const url = `${this.baseUrl}/api/actas/rti/configuration/options/${type}`;
        return this.http.get<ConditionsIndex[]>(url, { headers: this.setCommonHeaders(factoryId, userId) });
    }


    public setAlertMessage(alert: AlertGrid): void {
        this.alertMessage$.next(alert);
    }

    public getAlertMessage(): Observable<AlertGrid> {
        return this.alertMessage$.asObservable();
    }



    // --------- Index Attributes Endpoints

    public getIndexAttributesDataApi(factoryId: number, userId: number, articleNumber: string): Observable<IndexAttributes[]> {
        if (!articleNumber) return of([]);

        const url = `${this.baseUrl}/api/actas/rti/${articleNumber}/indexes`;
        return this.http.get<IndexAttributes[]>(url, { headers: this.setCommonHeaders(factoryId, userId) })
        .pipe(
            tap( (response: IndexAttributes[]) => response.map(item => {
                this.getIndexes().push(item.indexId);
            }))
        );
    }


    public postIndexAttributes(factoryId: number, userId: number, articleNumber: string, body: IndexAttributes): Observable<IndexAttributes> {
        const url = `${this.baseUrl}/api/actas/rti/${articleNumber}/indexes`;
        return this.http.post<IndexAttributes>(url, body, { headers: this.setCommonHeaders(factoryId, userId) })
    }


    public editIndexAttributesById(factoryId: number, userId: number, articleNumber: string, id: string, body: any): Observable<IndexAttributes> {
        const url = `${this.baseUrl}/api/actas/rti/${articleNumber}/indexes/${id}`;
        return this.http.patch<IndexAttributes>(url, body, { headers: this.setCommonHeaders(factoryId, userId) })
    }


    public getIndexes(): number[] {
        return this._indexes;
    }


    public getFactoryTypesIndexAttributes(factoryId: number, userId: number): Observable<KeyValue[]> {
        const url = `${this.baseUrl}/api/actas/rti/configuration/factoryTypes`;
        return this.http.get<KeyValue[]>(url, { headers: this.setCommonHeaders(factoryId, userId) });
    }


    // --------- Speed Measurements Endpoints
    
    public getSpeedMeasurementsDataApi(factoryId: number, userId: number, articleNumber: string): Observable<SpeedMeasurement[]> {
        if (!articleNumber) return of([]);

        const url = `${this.baseUrl}/api/actas/rti/${articleNumber}/speedMeasurements`;
        return this.http.get<SpeedMeasurement[]>(url, { headers: this.setCommonHeaders(factoryId, userId) });
    }
    
    
    public getReferenceMethodsByType(factoryId: number, userId: number, type: PointType): Observable<KeyValue[]> {
        const url = `${this.baseUrl}/api/actas/rti/configuration/referenceMethods/${type}`;
        return this.http.get<KeyValue[]>(url, { headers: this.setCommonHeaders(factoryId, userId) });
    }


    public postSpeedMeasurement(factoryId: number, userId: number, articleNumber: string, body: SpeedMeasurement): Observable<SpeedMeasurement> {
        const url = `${this.baseUrl}/api/actas/rti/${articleNumber}/speedMeasurements`;
        return this.http.post<SpeedMeasurement>(url, body, { headers: this.setCommonHeaders(factoryId, userId) })
    }


    public editSpeedMeasurementByIndex(factoryId: number, userId: number, articleNumber: string, operation: string, index: number, body: any): Observable<SpeedMeasurement> {
        const url = `${this.baseUrl}/api/actas/rti/${articleNumber}/speedMeasurements/measurement?operation=${operation}&index=${index}`;
        return this.http.patch<SpeedMeasurement>(url, body, { headers: this.setCommonHeaders(factoryId, userId) })
    }
    
    
    
    // --------- Sequence Tags Details Endpoints

    public getSequenceTagsDetailsDataApi(factoryId: number, userId: number, articleNumber: string): Observable<SequenceTagDetails[]> {
        if (!articleNumber) return of([]);

        const url = `${this.baseUrl}/api/actas/rti/${articleNumber}/sequenceTags`;
        return this.http.get<SequenceTagDetails[]>(url, { headers: this.setCommonHeaders(factoryId, userId) });
    }


    public postSequenceTagDetails(factoryId: number, userId: number, articleNumber: string, body: SequenceTagDetails): Observable<SequenceTagDetails> {
        const url = `${this.baseUrl}/api/actas/rti/${articleNumber}/sequenceTags`;
        return this.http.post<SequenceTagDetails>(url, body, { headers: this.setCommonHeaders(factoryId, userId) })
    }


    public editSequenceTagById(factoryId: number, userId: number, articleNumber: string, id: string, body: any): Observable<SequenceTagDetails> {
        const url = `${this.baseUrl}/api/actas/rti/${articleNumber}/sequenceTags/${id}`;
        return this.http.patch<SequenceTagDetails>(url, body, { headers: this.setCommonHeaders(factoryId, userId) })
    }


    // --------- Test Endpoints

    public getTestActasXmlFile(factoryId: number, userId: number, articleNumber: string, serialNumber: string, isDraft: boolean): Observable<string> {
        const url = `${this.baseUrl}/api/actas/tests/${articleNumber}/${serialNumber}?isDraft=${isDraft}`;
        return this.http.get(url, { 
            headers: {
                'factoryId': factoryId.toString(),
                'userId': userId.toString(),
                'Content-Type': 'application/xml'
            }, 
            responseType: 'text' 
          });
    }

    public getTestActasResults(factoryId: number, userId: number, articleNumber: string, serialNumber: string): Observable<TestsResults[]> {
        const url = `${this.baseUrl}/api/actas/tests/${articleNumber}/${serialNumber}/results`;
        return this.http.get<TestsResults[]>(url, { headers: this.setCommonHeaders(factoryId, userId) });
    }

    public getStatusXmlFileByProduct(factoryId: number, userId: number, articleNumber: string, serialNumber: string): Observable<VersionTests> {
        const url = `${this.baseUrl}/api/actas/tests/${articleNumber}/${serialNumber}/version`;
        return this.http.get<VersionTests>(url, { headers: this.setCommonHeaders(factoryId, userId) });
    }

    public postTestActasResults(factoryId: number, userId: number, articleNumber: string, serialNumber: string, formData: FormData): Observable<TestsResults[]> {
        const url = `${this.baseUrl}/api/actas/tests/${articleNumber}/${serialNumber}/results`;
        return this.http.post<TestsResults[]>(url, formData, { headers: this.setCommonHeaders(factoryId, userId) });
    }

    public getTestActasParameters(factoryId: number, userId: number, articleNumber: string, serialNumber: string): Observable<ActasParameters> {
        const url = `${this.baseUrl}/api/actas/tests/${articleNumber}/${serialNumber}/parameters`;
        return this.http.get<ActasParameters>(url, { headers: this.setCommonHeaders(factoryId, userId) });
    }

}