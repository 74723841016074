import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActasActionService } from '../actas-action.service';
// import { configurationData } from '../../../../../assets/files/actas_static_json';
import { configurationData } from '../../../../../assets/files/actas_CB_static_json';
import { HomeActionService } from 'src/app/featureModules/home/home-action.service';
@Component({
    selector: 'app-actas-static-template',
    templateUrl: './actas-static-template.component.html',
    styleUrls: ['./actas-static-template.component.scss']
})
export class ActasStaticTemplateComponent implements OnInit, OnChanges {
    userControls: FormGroup = new FormGroup({});
    @Input() actasData;
    @Input() isSubmitClicked;
    @Output() isActasDataValid = new EventEmitter();
    @Input() generateXML;

    configurationData = configurationData;
    hasChange: boolean;
    actasConfigurationData;

    constructor(private fb: FormBuilder, private actasService: ActasActionService,
        private homeServc: HomeActionService) {
    }

    ngOnInit() {
        // this.reset();
        // this.createForms();
    }
    ngOnChanges() {
        console.log(this.generateXML);
        if (this.actasData.isPO && this.generateXML === undefined) {
            this.actasService.getCircuitBreakerConfigurationDetails(this.actasData).subscribe((Response) => {
                this.actasConfigurationData = Response;
            });
        } else if (this.generateXML !== undefined) {
            this.getActasXMLFile();
        }
        // if (!this.actasData.isPO && this.actasData.productStepsId != null && !this.isSubmitClicked &&
        //     this.actasData.isFirstStep) {
        //     this.actasService.getCircuitBreakerConfigurationDetails(this.actasData).subscribe((Response) => {
        //         const data = JSON.parse(Response);
        //         const data1 = JSON.parse(data.UiJson);
        //         this.configurationData = data1;
        //         this.reset();
        //         this.createForms();
        //     }, (error) => {
        //         console.log(error);
        //         this.configurationData = configurationData;
        //         this.reset();
        //         this.createForms();
        //     }
        //     );
        // } else if (this.actasData.isPO) {
        //     this.configurationData = this.actasData.UIJSON;
        //     this.reset();
        //     this.createForms();
        // } else if (this.actasData.productStepsId === null) {
        //     this.configurationData = configurationData;
        //     this.reset();
        //     this.createForms();
        // } else if (this.isSubmitClicked && this.actasData.isFirstStep) {
        //     this.createJson();
        // }

    }
    createForms() {
        this.createCircuitBreakerControlsForm();
        // this.createFastActingEarthingSwicthControlsForm();
        this.createTestJobInformationControlsForm();
        // this.createDisconnectorControlsForm();
        this.createResistanceControlsForm();
        this.createTechnicalJobDataControlsForm();
        this.createVoltageControlsForm();
        this.createVoltageCircuitBreakerControlsForm();
        // this.createDSESVoltageControlsForm();
        this.createcircuitBreakerMeasurementPointsControlsForm();
        // this.createearthingSwitchMeasurementPointsControlsForm();
        if (!this.actasData.PO && !this.actasData.viewOnly) {
            this.onChanges();
        }
    }

    createCircuitBreakerControlsForm() {
        this.configurationData.circuitBreakerControls.forEach((indx) => {
            let data = new FormGroup({});
            if (indx.controlName === 'singleTextBox') {
                data = this.createSingleTextbox(indx);
            } else if (indx.controlName === 'dropDown') {
                data = this.createSingleDropDown(indx);
            } else if (indx.controlName === 'measurement') {
                data = this.createMeasurementBox(indx);
            } else if (indx.controlName === 'measurement dropdown') {
                data = this.createMeasurementDropdownBox(indx);
            } else if (indx.controlName === 'delay test section') {
                data = this.createDelayAfterTestBox(indx);
            }
            this.circuitBreakerControlsFormDetails().push(
                data
            );

        });
    }
    circuitBreakerControlsFormDetails(): FormArray {
        return this.userControls.get('circuitBreakerControlsForm') as FormArray;
    }

    createFastActingEarthingSwicthControlsForm() {
        this.configurationData.fastActingEarthingSwicthControls.forEach((indx) => {
            let data = new FormGroup({});
            if (indx.controlName === 'singleTextBox') {
                data = this.createSingleTextbox(indx);
            } else if (indx.controlName === 'dropDown') {
                data = this.createSingleDropDown(indx);
            } else if (indx.controlName === 'measurement') {
                data = this.createMeasurementBox(indx);
            } else if (indx.controlName === 'measurement dropdown') {
                data = this.createMeasurementDropdownBox(indx);
            }
            this.fastActingEarthingSwicthControlsDetails().push(
                data
            );
        });

    }

    fastActingEarthingSwicthControlsDetails(): FormArray {
        return this.userControls.get('fastActingEarthingSwicthControlsForm') as FormArray;
    }

    createTestJobInformationControlsForm() {
        this.configurationData.testJobInformationControls.forEach((indx) => {
            let data = new FormGroup({});
            if (indx.controlName === 'singleTextBox') {
                data = this.createSingleTextbox(indx);
            } else if (indx.controlName === 'dropDown') {
                data = this.createSingleDropDown(indx);
            } else if (indx.controlName === 'measurement') {
                data = this.createMeasurementBox(indx);
            } else if (indx.controlName === 'measurement dropdown') {
                data = this.createMeasurementDropdownBox(indx);
            }
            this.testJobInformationControlsDetails().push(
                data
            );
        });

    }

    testJobInformationControlsDetails(): FormArray {
        return this.userControls.get('testJobInformationControlsForm') as FormArray;
    }
    disconnectorControlsFormDetails(): FormArray {
        return this.userControls.get('disconnectorControlsForm') as FormArray;
    }
    createDisconnectorControlsForm() {
        this.configurationData.disconnectorControls.forEach((indx) => {
            let data = new FormGroup({});
            if (indx.controlName === 'singleTextBox') {
                data = this.createSingleTextbox(indx);
            } else if (indx.controlName === 'dropDown') {
                data = this.createSingleDropDown(indx);
            } else if (indx.controlName === 'measurement') {
                data = this.createMeasurementBox(indx);
            } else if (indx.controlName === 'measurement dropdown') {
                data = this.createMeasurementDropdownBox(indx);
            }
            this.disconnectorControlsFormDetails().push(
                data
            );
        });
    }
    circuitBreakerMeasurementPointsControlsFormDetails(): FormArray {
        return this.userControls.get('circuitBreakerMeasurementPointsControlsForm') as FormArray;
    }
    createcircuitBreakerMeasurementPointsControlsForm() {
        this.configurationData.circuitBreakerMeasurementPointsControl.forEach((indx) => {
            let data = new FormGroup({});
            data = this.createMeasurementPoints(indx);
            this.circuitBreakerMeasurementPointsControlsFormDetails().push(
                data
            );
        });
    }
    earthingSwitchMeasurementPointsControlsFormDetails(): FormArray {
        return this.userControls.get('earthingSwitchMeasurementPointsControlsForm') as FormArray;
    }
    createearthingSwitchMeasurementPointsControlsForm() {
        this.configurationData.earthingSwitchMeasurementPointsControl.forEach((indx) => {
            let data = new FormGroup({});
            data = this.createMeasurementPoints(indx);
            this.earthingSwitchMeasurementPointsControlsFormDetails().push(
                data
            );
        });
    }
    createResistanceControlsForm() {
        this.configurationData.resistanceControls.forEach((indx) => {
            let data = new FormGroup({});
            if (indx.controlName === 'singleTextBox') {
                data = this.createSingleTextbox(indx);
            } else if (indx.controlName === 'dropDown') {
                data = this.createSingleDropDown(indx);
            } else if (indx.controlName === 'measurement') {
                data = this.createMeasurementBox(indx);
            } else if (indx.controlName === 'measurement dropdown') {
                data = this.createMeasurementDropdownBox(indx);
            }
            this.resistanceControlsDetails().push(
                data
            );
        });

    }
    resistanceControlsDetails(): FormArray {
        return this.userControls.get('resistanceControlsForm') as FormArray;
    }
    createTechnicalJobDataControlsForm() {
        this.configurationData.technicalJobDataControls.forEach((indx) => {
            let data = new FormGroup({});
            if (indx.controlName === 'singleTextBox') {
                data = this.createSingleTextbox(indx);
            } else if (indx.controlName === 'dropDown') {
                data = this.createSingleDropDown(indx);
            } else if (indx.controlName === 'measurement') {
                data = this.createMeasurementBox(indx);
            } else if (indx.controlName === 'measurement dropdown') {
                data = this.createMeasurementDropdownBox(indx);
            }
            this.technicalJobDataControlsDetails().push(
                data
            );
        });

    }
    technicalJobDataControlsDetails(): FormArray {
        return this.userControls.get('technicalJobDataControlsForm') as FormArray;
    }
    createVoltageControlsForm() {
        this.configurationData.voltageControls.forEach((indx) => {
            let data = new FormGroup({});
            if (indx.controlName === 'singleTextBox') {
                data = this.createSingleTextbox(indx);
            } else if (indx.controlName === 'dropDown') {
                data = this.createSingleDropDown(indx);
            } else if (indx.controlName === 'measurement') {
                data = this.createMeasurementBox(indx);
            } else if (indx.controlName === 'measurement dropdown') {
                data = this.createMeasurementDropdownBox(indx);
            } else if (indx.controlName === 'textbox dropdown') {
                data = this.createTextBoxDropdownBox(indx);
            }
            this.voltageControlsDetails().push(
                data
            );
        });

    }
    voltageControlsDetails(): FormArray {
        return this.userControls.get('voltageControlsForm') as FormArray;
    }
    createVoltageCircuitBreakerControlsForm() {
        this.configurationData.voltageCircuitBreakerControls.forEach((indx) => {
            let data = new FormGroup({});
            if (indx.controlName === 'singleTextBox') {
                data = this.createSingleTextbox(indx);
            } else if (indx.controlName === 'dropDown') {
                data = this.createSingleDropDown(indx);
            } else if (indx.controlName === 'measurement') {
                data = this.createMeasurementBox(indx);
            } else if (indx.controlName === 'measurement dropdown') {
                data = this.createMeasurementDropdownBox(indx);
            } else if (indx.controlName === 'textbox dropdown') {
                data = this.createTextBoxDropdownBox(indx);
            }
            this.voltageCircuitBreakerControlsDetails().push(
                data
            );
        });

    }
    voltageCircuitBreakerControlsDetails(): FormArray {
        return this.userControls.get('voltageCircuitBreakerControlsForm') as FormArray;
    }
    createDSESVoltageControlsForm() {
        this.configurationData.dSESVoltageControls.forEach((indx) => {
            let data = new FormGroup({});
            if (indx.controlName === 'singleTextBox') {
                data = this.createSingleTextbox(indx);
            } else if (indx.controlName === 'dropDown') {
                data = this.createSingleDropDown(indx);
            } else if (indx.controlName === 'measurement') {
                data = this.createMeasurementBox(indx);
            } else if (indx.controlName === 'measurement dropdown') {
                data = this.createMeasurementDropdownBox(indx);
            }
            this.dSESVoltageControlsDetails().push(
                data
            );
        });

    }
    dSESVoltageControlsDetails(): FormArray {
        return this.userControls.get('dSESVoltageControlsForm') as FormArray;
    }
    createSingleTextbox(data): FormGroup {
        return this.fb.group({
            controlName: new FormControl({ value: data.controlName, disabled: this.actasData.viewOnly }),
            label: new FormControl({ value: data.label, disabled: this.actasData.viewOnly }),
            value: new FormControl({ value: data.value, disabled: this.actasData.viewOnly }),
            backendLabel: new FormControl({ value: data.backendLabel, disabled: this.actasData.viewOnly })
        });
    }

    createSingleDropDown(data): FormGroup {
        return this.fb.group({
            controlName: new FormControl({ value: data.controlName, disabled: this.actasData.viewOnly }),
            label: new FormControl({ value: data.label, disabled: this.actasData.viewOnly }),
            dropDownArrayValue: new FormControl({ value: data.dropDownArrayValue, disabled: this.actasData.viewOnly }),
            dropDownSelectedValue: new FormControl({ value: data.dropDownSelectedValue, disabled: this.actasData.viewOnly }),
            backendLabelDropdown: new FormControl({ value: data.backendLabelDropdown, disabled: this.actasData.viewOnly })
        });
    }

    createMeasurementBox(data): FormGroup {
        return this.fb.group({
            controlName: new FormControl({ value: data.controlName, disabled: this.actasData.viewOnly }),
            label: new FormControl({ value: data.label, disabled: this.actasData.viewOnly }),
            maxvalue: new FormControl({ value: data.maxvalue, disabled: this.actasData.viewOnly }),
            minvalue: new FormControl({ value: data.minvalue, disabled: this.actasData.viewOnly }),
            unit: new FormControl({ value: data.unit, disabled: this.actasData.viewOnly }),
            backendLabelMaxValue: new FormControl({ value: data.backendLabelMaxValue, disabled: this.actasData.viewOnly }),
            backendLabelMinValue: new FormControl({ value: data.backendLabelMinValue, disabled: this.actasData.viewOnly }),
            backendLabelUnit: new FormControl({ value: data.backendLabelUnit, disabled: this.actasData.viewOnly }),
        });
    }

    createMeasurementDropdownBox(data): FormGroup {
        return this.fb.group({
            controlName: new FormControl({ value: data.controlName, disabled: this.actasData.viewOnly }),
            label: new FormControl({ value: data.label, disabled: this.actasData.viewOnly }),
            maxvalue: new FormControl({ value: data.maxvalue, disabled: this.actasData.viewOnly }),
            minvalue: new FormControl({ value: data.minvalue, disabled: this.actasData.viewOnly }),
            unitArrayValue: new FormControl({ value: data.unitArrayValue, disabled: this.actasData.viewOnly }),
            selectedUnitValue: new FormControl({ value: data.selectedUnitValue, disabled: this.actasData.viewOnly }),
            backendLabelMaxValue: new FormControl({ value: data.backendLabelMaxValue, disabled: this.actasData.viewOnly }),
            backendLabelMinValue: new FormControl({ value: data.backendLabelMinValue, disabled: this.actasData.viewOnly }),
            backendLabelDropdown: new FormControl({ value: data.backendLabelDropdown, disabled: this.actasData.viewOnly })
        });
    }

    createTextBoxDropdownBox(data): FormGroup {
        return this.fb.group({
            controlName: new FormControl({ value: data.controlName, disabled: this.actasData.viewOnly }),
            label: new FormControl({ value: data.label, disabled: this.actasData.viewOnly }),
            value: new FormControl({ value: data.value, disabled: this.actasData.viewOnly }),
            unitArrayValue: new FormControl({ value: data.unitArrayValue, disabled: this.actasData.viewOnly }),
            selectedUnitValue: new FormControl({ value: data.selectedUnitValue, disabled: this.actasData.viewOnly }),
            backendLabel: new FormControl({ value: data.backendLabel, disabled: this.actasData.viewOnly }),
            backendLabelUnitArrayValue: new FormControl({ value: data.backendLabelUnitArrayValue, disabled: this.actasData.viewOnly }),
            backendLabelDropdown: new FormControl({ value: data.backendLabelDropdown, disabled: this.actasData.viewOnly }),
            unit: new FormControl({ value: data.unit, disabled: this.actasData.viewOnly })
        });
    }
    createMeasurementPoints(data): FormGroup {
        return this.fb.group({
            controlName: new FormControl({ value: data.controlName, disabled: this.actasData.viewOnly }),
            heading: new FormControl({ value: data.heading, disabled: this.actasData.viewOnly }),
            headerForLP: new FormControl({ value: data.headerForLP, disabled: this.actasData.viewOnly }),
            headerForUP: new FormControl({ value: data.headerForUP, disabled: this.actasData.viewOnly }),
            maxvalueForLP: new FormControl({ value: data.maxvalueForLP, disabled: this.actasData.viewOnly }),
            minvalueForLP: new FormControl({ value: data.minvalueForLP, disabled: this.actasData.viewOnly }),
            maxvalueForUP: new FormControl({ value: data.maxvalueForUP, disabled: this.actasData.viewOnly }),
            minvalueForUP: new FormControl({ value: data.minvalueForUP, disabled: this.actasData.viewOnly }),
            unitArrayValueForLP: new FormControl({ value: data.unitArrayValueForLP, disabled: this.actasData.viewOnly }),
            selectedUnitValueForLP: new FormControl({ value: data.selectedUnitValueForLP, disabled: this.actasData.viewOnly }),
            unitArrayValueForUP: new FormControl({ value: data.unitArrayValueForUP, disabled: this.actasData.viewOnly }),
            selectedUnitValueForUP: new FormControl({ value: data.selectedUnitValueForUP, disabled: this.actasData.viewOnly }),
            dropDownArrayValueForLP: new FormControl({ value: data.dropDownArrayValueForLP, disabled: this.actasData.viewOnly }),
            dropDownSelectedValueForLP: new FormControl({ value: data.dropDownSelectedValueForLP, disabled: this.actasData.viewOnly }),
            dropDownArrayValueForUP: new FormControl({ value: data.dropDownArrayValueForUP, disabled: this.actasData.viewOnly }),
            dropDownSelectedValueForUP: new FormControl({ value: data.dropDownSelectedValueForUP, disabled: this.actasData.viewOnly }),
            backendLabelMaxValueUP: new FormControl({ value: data.backendLabelMaxValueUP, disabled: this.actasData.viewOnly }),
            backendLabelMaxValueLP: new FormControl({ value: data.backendLabelMaxValueLP, disabled: this.actasData.viewOnly }),
            backendLabelMinValueUP: new FormControl({ value: data.backendLabelMinValueUP, disabled: this.actasData.viewOnly }),
            backendLabelMinValueLP: new FormControl({ value: data.backendLabelMinValueLP, disabled: this.actasData.viewOnly }),
            backendLabelDopdownValueUP: new FormControl({ value: data.backendLabelDopdownValueUP, disabled: this.actasData.viewOnly }),
            backendLabelDopdownValueLP: new FormControl({ value: data.backendLabelDopdownValueLP, disabled: this.actasData.viewOnly }),
            backendLabelUnitArrayValueUP: new FormControl({ value: data.backendLabelUnitArrayValueUP, disabled: this.actasData.viewOnly }),
            backendLabelUnitArrayValueLP: new FormControl({ value: data.backendLabelUnitArrayValueLP, disabled: this.actasData.viewOnly })
        });
    }

    createDelayAfterTestBox(data): FormGroup {
        return this.fb.group({
            controlName: new FormControl({ value: data.controlName, disabled: this.actasData.viewOnly }),
            label: new FormControl({ value: data.label, disabled: this.actasData.viewOnly }),
            textLabel1: new FormControl({ value: data.textLabel1, disabled: this.actasData.viewOnly }),
            backendTextLabel1: new FormControl({ value: data.backendTextLabel1, disabled: this.actasData.viewOnly }),
            valueLabel1: new FormControl({ value: data.valueLabel1, disabled: this.actasData.viewOnly }),
            textLabel2: new FormControl({ value: data.textLabel2, disabled: this.actasData.viewOnly }),
            backendTextLabel2: new FormControl({ value: data.backendTextLabel2, disabled: this.actasData.viewOnly }),
            valueLabel2: new FormControl({ value: data.valueLabel2, disabled: this.actasData.viewOnly }),
            textLabel3: new FormControl({ value: data.textLabel3, disabled: this.actasData.viewOnly }),
            backendTextLabel3: new FormControl({ value: data.backendTextLabel3, disabled: this.actasData.viewOnly }),
            valueLabel3: new FormControl({ value: data.valueLabel3, disabled: this.actasData.viewOnly }),
            textLabel4: new FormControl({ value: data.textLabel4, disabled: this.actasData.viewOnly }),
            backendTextLabel4: new FormControl({ value: data.backendTextLabel4, disabled: this.actasData.viewOnly }),
            valueLabel4: new FormControl({ value: data.valueLabel4, disabled: this.actasData.viewOnly }),
        });

    }
    createJson() {
        let backendJSON = {}, jsonData;
        let testJobDataJSON = {}, techJobDataJSON = {}, resistanceJSON = {},
            voltageJSON = {}, voltageCBJSON = {}, circuitBreakerControlsFormJSON = {},
            circuitBreakerMeasurementPointsControlJSON = {};

        this.testJobInformationControlsDetails().controls.forEach(element => {
            jsonData = this.getKeyValueData(element);
            testJobDataJSON = { ...testJobDataJSON, ...jsonData };
        });
        this.technicalJobDataControlsDetails().controls.forEach(element => {
            jsonData = this.getKeyValueData(element);
            techJobDataJSON = { ...techJobDataJSON, ...jsonData };
        });
        this.resistanceControlsDetails().controls.forEach(element => {
            jsonData = this.getKeyValueData(element);
            resistanceJSON = { ...resistanceJSON, ...jsonData };
        });
        this.voltageControlsDetails().controls.forEach(element => {
            jsonData = this.getKeyValueData(element);
            voltageJSON = { ...voltageJSON, ...jsonData };
        });
        this.voltageCircuitBreakerControlsDetails().controls.forEach(element => {
            jsonData = this.getKeyValueData(element);
            voltageCBJSON = { ...voltageCBJSON, ...jsonData };
        });
        this.circuitBreakerControlsFormDetails().controls.forEach(element => {
            jsonData = this.getKeyValueData(element);
            circuitBreakerControlsFormJSON = { ...circuitBreakerControlsFormJSON, ...jsonData };
        });
        this.circuitBreakerMeasurementPointsControlsFormDetails().controls.forEach(element => {
            jsonData = this.getKeyValueDataForMeasurementPoints(element);
            circuitBreakerMeasurementPointsControlJSON = { ...circuitBreakerMeasurementPointsControlJSON, ...jsonData };
        });

        backendJSON = {
            'TestJobInformation': testJobDataJSON,
            'TechnicalJobdata': techJobDataJSON,
            'Voltage': voltageJSON,
            'Voltage_CB': voltageCBJSON,
            'CircuitBreaker': circuitBreakerControlsFormJSON,
            'MeasurementPolicies': circuitBreakerMeasurementPointsControlJSON,
            'Resistance': resistanceJSON
        };

        const UIJSON = {
            'testJobInformationControls': this.userControls.value.testJobInformationControlsForm,
            'technicalJobDataControls': this.userControls.value.technicalJobDataControlsForm,
            'resistanceControls': this.userControls.value.resistanceControlsForm,
            'voltageControls': this.userControls.value.voltageControlsForm,
            'voltageCircuitBreakerControls': this.userControls.value.voltageCircuitBreakerControlsForm,
            'circuitBreakerControls': this.userControls.value.circuitBreakerControlsForm,
            'circuitBreakerMeasurementPointsControl': this.userControls.value.circuitBreakerMeasurementPointsControlsForm,
        };
        console.log(backendJSON);
        const data = {
            JsonString: JSON.stringify(backendJSON),
            UIString: JSON.stringify(UIJSON),
            productStepsId: this.actasData.productStepsId
        };
        this.actasService.saveCircuitBreakerTemplate(data).subscribe((response) => {
            console.log(response);
        }, (error) => {
            console.log(error);
        });
    }

    getKeyValueData(element) {
        const json = {};
        if (element.get('controlName').value === 'singleTextBox') {
            if (element.get('backendLabel').value !== null && element.get('backendLabel').value !== ''
                && element.get('backendLabel').value !== undefined) {
                json[element.get('backendLabel').value] = element.get('value').value;
            }
        } else if (element.get('controlName').value === 'dropDown') {
            if (element.get('backendLabelDropdown').value !== null && element.get('backendLabelDropdown').value !== ''
                && element.get('backendLabelDropdown').value !== undefined) {
                json[element.get('backendLabelDropdown').value] = element.get('dropDownSelectedValue').value;
            }
        } else if (element.get('controlName').value === 'measurement') {
            if (element.get('backendLabelMaxValue').value !== null && element.get('backendLabelMaxValue').value !== ''
                && element.get('backendLabelMaxValue').value !== undefined) {
                json[element.get('backendLabelMaxValue').value] = element.get('maxvalue').value;
            }
            if (element.get('backendLabelMinValue').value !== null && element.get('backendLabelMinValue').value !== ''
                && element.get('backendLabelMinValue').value !== undefined) {
                json[element.get('backendLabelMinValue').value] = element.get('minvalue').value;
            }
            if (element.get('backendLabelUnit').value !== null && element.get('backendLabelUnit').value !== ''
                && element.get('backendLabelUnit').value !== undefined) {
                json[element.get('backendLabelUnit').value] = element.get('unit').value;
            }
        } else if (element.get('controlName').value === 'measurement dropdown') {
            if (element.get('backendLabelMaxValue').value !== null && element.get('backendLabelMaxValue').value !== ''
                && element.get('backendLabelMaxValue').value !== undefined) {
                json[element.get('backendLabelMaxValue').value] = element.get('maxvalue').value;
            }
            if (element.get('backendLabelMinValue').value !== null && element.get('backendLabelMinValue').value !== ''
                && element.get('backendLabelMinValue').value !== undefined) {
                json[element.get('backendLabelMinValue').value] = element.get('minvalue').value;
            }
            if (element.get('backendLabelDropdown').value !== null && element.get('backendLabelDropdown').value !== ''
                && element.get('backendLabelDropdown').value !== undefined) {
                json[element.get('backendLabelDropdown').value] = element.get('selectedUnitValue').value;
            }
        } else if (element.get('controlName').value === 'textbox dropdown') {
            if (element.get('backendLabel').value !== null && element.get('backendLabel').value !== ''
                && element.get('backendLabel').value !== undefined) {
                json[element.get('backendLabel').value] = element.get('value').value;
            }
            if (element.get('backendLabelDropdown').value !== null && element.get('backendLabelDropdown').value !== ''
                && element.get('backendLabelDropdown').value !== undefined) {
                json[element.get('backendLabelDropdown').value] = element.get('selectedUnitValue').value;
            }
        } else if (element.get('controlName').value === 'delay test section') {
            if (element.get('backendTextLabel1').value !== null && element.get('backendTextLabel1').value !== ''
                && element.get('backendTextLabel1').value !== undefined) {
                json[element.get('backendTextLabel1').value] = element.get('valueLabel1').value;
            }
            if (element.get('backendTextLabel2').value !== null && element.get('backendTextLabel2').value !== ''
                && element.get('backendTextLabel2').value !== undefined) {
                json[element.get('backendTextLabel2').value] = element.get('valueLabel2').value;
            }
            if (element.get('backendTextLabel3').value !== null && element.get('backendTextLabel3').value !== ''
                && element.get('backendTextLabel3').value !== undefined) {
                json[element.get('backendTextLabel3').value] = element.get('valueLabel3').value;
            }
            if (element.get('backendTextLabel4').value !== null && element.get('backendTextLabel4').value !== ''
                && element.get('backendTextLabel4').value !== undefined) {
                json[element.get('backendTextLabel4').value] = element.get('valueLabel4').value;
            }
        }

        return json;

    }
    getKeyValueDataForMeasurementPoints(element) {
        const json = {};

        if (element.get('backendLabelDopdownValueUP').value !== null && element.get('backendLabelDopdownValueUP').value !== '' &&
            element.get('backendLabelDopdownValueUP').value !== undefined) {
            json[element.get('backendLabelDopdownValueUP').value] = element.get('dropDownSelectedValueForUP').value;
        }

        if (element.get('backendLabelDopdownValueLP').value !== null && element.get('backendLabelDopdownValueLP').value !== '' &&
            element.get('backendLabelDopdownValueLP').value !== undefined) {
            json[element.get('backendLabelDopdownValueLP').value] = element.get('dropDownSelectedValueForLP').value;
        }

        if (element.get('backendLabelMaxValueLP').value !== null && element.get('backendLabelMaxValueLP').value !== '' &&
            element.get('backendLabelMaxValueLP').value !== undefined) {
            json[element.get('backendLabelMaxValueLP').value] = element.get('minvalueForLP').value;
        }

        if (element.get('backendLabelMaxValueUP').value !== null && element.get('backendLabelMaxValueUP').value !== '' &&
            element.get('backendLabelMaxValueUP').value !== undefined) {
            json[element.get('backendLabelMaxValueUP').value] = element.get('minvalueForUP').value;
        }

        if (element.get('backendLabelMinValueLP').value !== null && element.get('backendLabelMinValueLP').value !== '' &&
            element.get('backendLabelMinValueLP').value !== undefined) {
            json[element.get('backendLabelMinValueLP').value] = element.get('maxvalueForLP').value;
        }

        if (element.get('backendLabelMinValueUP').value !== null && element.get('backendLabelMinValueUP').value !== '' &&
            element.get('backendLabelMinValueUP').value !== undefined) {
            json[element.get('backendLabelMinValueUP').value] = element.get('maxvalueForUP').value;
        }

        if (element.get('backendLabelUnitArrayValueLP').value !== null && element.get('backendLabelUnitArrayValueLP').value !== '' &&
            element.get('backendLabelUnitArrayValueLP').value !== undefined) {
            json[element.get('backendLabelUnitArrayValueLP').value] = element.get('selectedUnitValueForLP').value;
        }

        if (element.get('backendLabelUnitArrayValueUP').value !== null && element.get('backendLabelUnitArrayValueUP').value !== '' &&
            element.get('backendLabelUnitArrayValueUP').value !== undefined) {
            json[element.get('backendLabelUnitArrayValueUP').value] = element.get('selectedUnitValueForUP').value;
        }

        return json;

    }

    reset() {
        this.userControls = this.fb.group({
            circuitBreakerControlsForm: this.fb.array([]),
            // fastActingEarthingSwicthControlsForm: this.fb.array([]),
            testJobInformationControlsForm: this.fb.array([]),
            // disconnectorControlsForm: this.fb.array([]),
            resistanceControlsForm: this.fb.array([]),
            technicalJobDataControlsForm: this.fb.array([]),
            voltageControlsForm: this.fb.array([]),
            voltageCircuitBreakerControlsForm: this.fb.array([]),
            // dSESVoltageControlsForm: this.fb.array([]),
            circuitBreakerMeasurementPointsControlsForm: this.fb.array([]),
            // earthingSwitchMeasurementPointsControlsForm: this.fb.array([])
        });
    }

    onChanges() {
        this.userControls.valueChanges.subscribe(val => {
            this.isActasDataValid.emit('true');
            // if (this.userControls.invalid && this.userControls.dirty) {
            //     this.isActasDataValid.emit('false');
            // } else {
            //     this.isActasDataValid.emit('true');
            // }
        });

    }

    validationCheckForNumber(e) {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode !== 46 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    getActasXMLFile() {
        this.homeServc.generateActasXml(this.generateXML).subscribe((Response) => {
            const blob = new Blob([Response.body], { type: 'text/xml' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const fileName = this.actasConfigurationData.orderNumber + '_' + this.generateXML.SerialNumber
                + '_' + new Date();
            link.download = decodeURI(fileName);
            link.click();
        }, ((error) => {
            console.log(error);
        })
        );
    }

}
