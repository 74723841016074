import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ErrorReason } from '../../additional-config-points/actas/actas-product-details-view/interfaces/error-service.interface';
import { ActasProductDetailsService } from '../../additional-config-points/actas/actas-product-details-view/services/actas-product-details.service';
import { takeUntil } from 'rxjs/operators';
import { ActasParameters, Basic, Information, Item, Speed, TechnicalData } from '../interfaces/actas-parameters.interface';

@Component({
  selector: 'actas-testing-parameters',
  templateUrl: './actas-testing-parameters.component.html',
  styleUrls: ['./actas-testing-parameters.component.scss']
})
export class ActasTestingParametersComponent implements OnInit, OnDestroy {

  @Input() public factoryId: number;
  @Input() public userId: number;
  @Input() public articleNumber: string;
  @Input() public serialNumber: string;

  private readonly destroy$: Subject<void> = new Subject<void>();

  public isSpinnerEnabled: boolean = false;
  public showErrorAlert: boolean = false;
  public errorsService: ErrorReason[] = [];
  public parametersBasicData: Basic;
  public parametersTechnicalData: TechnicalData;
  public parametersInformation: Information[];
  public parametersSpeedMeasurement: Speed[];

  constructor(
    private actasProductDetailsService: ActasProductDetailsService
  ) { }

  ngOnInit() {
    this.getAllTestParameters(this.factoryId, this.userId, this.articleNumber, this.serialNumber);
  }


  private getAllTestParameters(factoryId: number, userId: number, articleNumber: string, serialNumber: string) {
    this.isSpinnerEnabled = true;
    this.actasProductDetailsService.getTestActasParameters(factoryId, userId, articleNumber, serialNumber)
    .pipe( takeUntil(this.destroy$ ))
    .subscribe({
      next: (response: ActasParameters) => {
          this.parametersBasicData = response.basic;
          this.parametersTechnicalData = response.technicalData;
          this.parametersInformation = response.information;
          this.parametersSpeedMeasurement = response.speed;
        },
        error: () => {
          this.isSpinnerEnabled = false;
        },
        complete: () => {
          this.isSpinnerEnabled = false;
        }
      }      
    )
  }

  getReferenceMethodByPoint(
    point: 'Upper Point' | 'Lower Point', 
    arrayMethods: Item[],
    property: 'refMethod' | 'refMethodValue' | 'offset'
  ): string {
    switch (property) {
      case 'refMethod':
        return arrayMethods.find(item => item.point === point).refMethod;
      case 'refMethodValue':
        return arrayMethods.find(item => item.point === point).refMethodValue;
      case 'offset':
        return arrayMethods.find(item => item.point === point).offset;
      default:
        return '-';
    }
  }
     

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
  }

}
