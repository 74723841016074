import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { DragdropDirective } from './drag drop directive/dragdrop.directive';
import { SharedService } from './shared.service';
import { ZXingScannerModule } from './zxing-scanner/zxing-scanner.module';
import { CommonModule } from '../../../node_modules/@angular/common';
import { PrivacyPolicyComponent } from './footer/privacy-policy/privacy-policy.component';
import { OrderByQltPipe } from './sorting.pipe';
import { ServertimeoutComponent } from './servertimeout/servertimeout.component';
import { MatBadgeModule, MatProgressSpinnerModule } from '@angular/material';
import { ScrollTotopComponent } from './scroll-totop/scroll-totop.component';
import { ActasStaticTemplateComponent } from '../featureModules/admin/actas/actas-static-template/actas-static-template.component';
import { FormControlTrimDirectiveDirective } from './trim-directive/form-control-trim-directive.directive' ;
import { ActasTestingParametersComponent } from '../featureModules/admin/actas/actas-testing-parameters/actas-testing-parameters.component';
import { OperationSpeedMeasurementPipe } from '../featureModules/admin/additional-config-points/actas/actas-product-details-view/pipes/operation-speed-measurement.pipe';
// import { MatTreeModule } from '@angular/material/tree';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    HeaderComponent, 
    NotfoundComponent,
    FooterComponent, 
    LoaderComponent, 
    DragdropDirective, 
    PrivacyPolicyComponent, 
    ServertimeoutComponent,
    ScrollTotopComponent, 
    ActasStaticTemplateComponent, 
    FormControlTrimDirectiveDirective,
    ActasTestingParametersComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ZXingScannerModule,
    MatBadgeModule,
    MatProgressSpinnerModule
  ],
  exports: [
    ZXingScannerModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    HeaderComponent,
    FooterComponent,
    FormsModule,
    LoaderComponent,
    DragdropDirective,
    ScrollTotopComponent,
    ActasStaticTemplateComponent,
    FormControlTrimDirectiveDirective,
    ActasTestingParametersComponent
  ],
  providers: [
    DragdropDirective, 
    FormControlTrimDirectiveDirective,
    OperationSpeedMeasurementPipe
  ]
})
export class SharedModule { }
