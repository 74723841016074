import { Pipe, PipeTransform } from "@angular/core";
import { Operation } from "../interfaces/operation-speed-measurement.enum";


@Pipe({
    name: 'operationSpeedMeasurement'
})
export class OperationSpeedMeasurementPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'close':
                return Operation.CLOSE;
            case 'open':
                return Operation.OPEN;
            default:
                return value;
        }
    }
}