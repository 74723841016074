export const environment = {
    production: true,
    BASE_URL: "https://dev.webapi.digitalpassportsystem.hitachienergy.com:443/",
    appInsights: "7bf7fd2b-8499-4a29-9aa6-390795fee29c",
    clientID: "195bb382-a774-40d3-8a99-4d0334856276",
    scopes: ["User.ReadBasic.All", "7ed5b4d5-dc71-4950-b63c-a31a60184f8f/Access_dps_api", "User.Read"],
    redirectURi: "https://dev.digitalpassportsystem.hitachienergy.com",
    subscription: "6e62451ba453424794465bb6f0f6a957",
    authority: "https://login.microsoftonline.com/7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195",
    blobContainer: "pglz01-ne-stblob-dps-102",
    azureSearchKey: "cbcPhQpPHOnAK6ty0xliNRa1U9h3r6aXwTn2IK2fZnAzSeBLA7NF",
    azureSearchURL: "https://dev.search.digitalpassportsystem.hitachienergy.com",
    bingCredential: "AhHGm49KKwySazCHIgNbmzE0l9hNR35wKyMGwJbhrKUmg_AIpLyjX-uqiBMGUdIf",
    azureMapKey: "XlfDX5Cd0QoxsU1EYiFqghl5WP48zKz29CIB60jNcdk",
    title: "-  DEV",
    blobContainerCustomReport: "pglz01-ne-stblob-dps-103",
    downloadZip: "https://dev.export.digitalpassportsystem.hitachienergy.com/api/zip?code=jwBcsn64kyHnauoMtStv7_ArDTYi58HZWL4SWjhOnj-uAzFu53nfGw==",
    CCRPUrl: "https://dev.ccrp.hitachienergy.com/en/complaint/",
    clientSecret: "31355f94-7268-4fe4-a3dd-f023429105ae",
    isCCRP: "yes",
    CCRPAppId: "9AAG029588",
    ServIsAppId: "9AAG029556",
    organizationName: "HE",
    signlarUrl: "https://dev.download.digitalpassportsystem.hitachienergy.com/api/negotiate?code=tlMjwtTPf6_knSBB3RNN14jmX4R8OUyGLZnajdLg-YkGAzFuox6sJQ==",
    productHierarchy: "https://dev.export.digitalpassportsystem.hitachienergy.com/api/ProductHierarchy?code=nP5P66HunFCR5pgANmoIDhRpErqi7kG0bzuXUVMjPpnAAzFuNZ9VRQ==",
    generateReportProductionTimeCaptured: "https://dev.export.digitalpassportsystem.hitachienergy.com/api/GenerateReportForProductionTimeCaptureDetails?code=MiO-mfP2sr988QLG2RYB1bsFx-QvzHKaqlOuUgpEN-ROAzFuGfFmhA==",
    environment: "HITACHI",
    downloadQCZip: "https://dev.export.digitalpassportsystem.hitachienergy.com/api/ProductMultipleDocumentsDownload?code=HjtegqJzwHkbJ7v6-ys9KCpXxtsbuIoO9SuSLWZP-3ppAzFuyjWyOw==",
    ACTAS_BASE_URI: "https://hpg-webapp-000255.azurewebsites.net"
};
